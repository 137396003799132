@use "settings-variables" as *;
/* ––––––––––BASIC ELEMENTS–––––––––– */
#sun {
  background-color: $color-surface-primary;
  flex: 1; //fills space

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    max-width: 24rem;
    padding: 0 1rem;
    padding-bottom: 2rem; //to equalize the space between the sun and the text
  }
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-tablet) {
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop) {
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop-l) {
}
