@use "settings-variables" as *;
/* ––––––––––HEADER–––––––––– */

header.header {
  background-color: $color-surface-primary;
  color: $color-on-surface-primary;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;

  h1,
  a {
    @include font-style(l);
  }

  a {
    text-align: right;
    color: inherit;
    text-decoration: none;
    height: min-content;

    &:hover {
      color: $color-on-surface-primary-hover;
    }
    &:focus {
      color: $color-on-surface-primary-focus;
    }
  }
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-tablet) {
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop) {
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop-l) {
}
