@font-face {
  font-family: "GTEesti";
  src: url("/assets/fonts/gt-eesti/GT-Eesti-Text-Regular.otf")
    format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GTEesti";
  src: url("/assets/fonts/gt-eesti/GT-Eesti-Text-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GTEesti";
  src: url("/assets/fonts/gt-eesti/GT-Eesti-Text-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
