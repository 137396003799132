@use "settings-variables" as *;

/* ––––––––––TYPOGRAPHY–––––––––– */
body {
  font-family: GTEesti, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.5;

  @include font-style(base);
}

h1 {
  @include font-style(4xl);
}

h2 {
  @include font-style(3xl);
}

h3 {
  @include font-style(2xl);
}

h4 {
  @include font-style(xl);
}

h5 {
  @include font-style(l);
}

h6 {
  @include font-style(m);
}

p,
a,
span,
div {
  @include font-style(base);
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-tablet) {
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop) {
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop-l) {
}
