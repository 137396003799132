@use "settings-variables" as *;

/* –––––––––––––––––––––––––––––– GENERAL –––––––––––––––––––––––––––––– */
html,
body {
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: $color-surface-secondary;
  color: $color-on-surface-secondary;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

img {
  /* width: 100%; */
  max-width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-tablet) {
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop) {
  .wrapper {
    padding: 2rem;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop-l) {
}
